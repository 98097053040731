.post-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .post-item {
    width: calc(50% - 10px);
    position: relative;
    margin-bottom: 20px;

    .post-image {
      position: relative;
    }

    .tags-container {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      z-index: 1;

      .tag-item {
        padding: 2px 7px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
        color: #ffffff;
        font-size: 0.8125rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .post-item {
    width: calc(50% - 10px);
  }

  .tag-filter-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .post-item {
    width: 100%;
  }

  .tag-filter-container {
    flex-direction: column;
    align-items: flex-start;

    li {
      width: 100%;
      text-align: left;
    }
  }
}

@media (max-width: 576px) {
  .post-item {
    padding: 0 5px;
    width: 100% !important;
  }

  .tag-filter-container li {
    font-size: 0.75rem;
  }
}
