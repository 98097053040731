.image-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-big-image {
  text-align: center;
  max-width: 80%;
  color: white;
}
